import softsimApi from "@/plugins/softsimApi";

const state = {
    loading: true,
    blogs:[],
}


const getters = {
    getBlogs(state) {
        var result = [...state.blogs];
        return result;
    }
}

const actions = {
    addBlog(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.postRequest("/blog", payload).then(response => {
            context.commit('addBlog',response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    updateBlog(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.patchRequest("/blog/"+payload.id, payload).then(response=>{
            context.commit('updateBlog', response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    deleteBlog(context, payload ){
        context.commit('setLoading',true);
        return softsimApi.deleteRequest( `/blog/`+ payload.id).then(response=>{
            context.commit('deleteBlog', response.data );
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    retrieveBlogs(context) {
        return softsimApi.getRequest('/blog' ).then(response => {
            context.commit('retrieveBlogs', response.data);
            context.commit('setLoading',false);
        });
    },
} 


const mutations = {
    setLoading(state,payload){
        state.loading=payload;
    },
    addBlog(state,payload){
        state.blogs.unshift(payload);
    },
    updateBlog(state,payload){
        const edited_item_index=state.blogs.findIndex(
            (item) => item.id === payload.id
          );
        if (edited_item_index !== -1)
            state.blogs[edited_item_index] = payload;
    },
    deleteBlog(state,payload){
        state.blogs = state.blogs.filter((blog)=>{
            return blog.id!=payload.id;
        });
    },
    retrieveBlogs(state,payload){
        state.blogs=payload.data;
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
