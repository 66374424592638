import softsimApi from "@/plugins/softsimApi";

const state = {
    loading: true,
    services:[],
}


const getters = {
    getServices(state) {
        var result = [...state.services];
        return result;
    }
}

const actions = {
    addService(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.postRequest("/service", payload).then(response => {
            context.commit('addService',response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    updateService(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.patchRequest("/service/"+payload.id, payload).then(response=>{
            context.commit('updateService', response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    deleteService(context, payload ){
        context.commit('setLoading',true);
        return softsimApi.deleteRequest( `/service/`+ payload.id).then(response=>{
            context.commit('deleteService', response.data );
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    retrieveServices(context) {
        return softsimApi.getRequest('/service' ).then(response => {
            context.commit('retrieveServices', response.data);
            context.commit('setLoading',false);
        });
    },

} 


const mutations = {
    setLoading(state,payload){
        state.loading=payload;
    },
    addService(state,payload){
        state.services.unshift(payload);
    },
    updateService(state,payload){
        const edited_item_index=state.services.findIndex(
            (item) => item.id === payload.id
            );
        if (edited_item_index !== -1)
            state.services[edited_item_index] = payload;
    },
    deleteService(state,payload){
        state.services = state.services.filter((service)=>{
            return service.id!=payload.id;
        });
    },
    retrieveServices(state,payload){
        state.services=payload.data;
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
