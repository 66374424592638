import softsimApi from "@/plugins/softsimApi";

const state = {
    loading: true,
    opportunities:[],
}


const getters = {
    getOpportunities(state) {
        var result = [...state.opportunities];
        return result;
    }
}

const actions = {
    addOpportunity(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.postRequest("/opportunity", payload).then(response => {
            context.commit('addOpportunity',response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    updateOpportunity(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.patchRequest("/opportunity/"+payload.id, payload).then(response=>{
            context.commit('updateOpportunity', response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    deleteOpportunity(context, payload ){
        context.commit('setLoading',true);
        return softsimApi.deleteRequest( `/opportunity/`+ payload.id).then(response=>{
            context.commit('deleteOpportunity', response.data );
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    retrieveOpportunities(context) {
        return softsimApi.getRequest('/opportunity' ).then(response => {
            context.commit('retrieveOpportunities', response.data);
            context.commit('setLoading',false);
        });
    },

} 


const mutations = {
    setLoading(state,payload){
        state.loading=payload;
    },
    addOpportunity(state,payload){
        state.opportunities.unshift(payload);
    },
    updateOpportunity(state,payload){
        const edited_item_index=state.opportunities.findIndex(
            (item) => item.id === payload.id
            );
        if (edited_item_index !== -1)
            state.opportunities[edited_item_index] = payload;
    },
    deleteOpportunity(state,payload){
        state.opportunities = state.opportunities.filter((opportunity)=>{
            return opportunity.id!=payload.id;
        });
    },
    retrieveOpportunities(state,payload){
        state.opportunities=payload.data;
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
