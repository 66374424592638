import softsimApi from "@/plugins/softsimApi";

const state = {
    loading: true,
    users:[],
    user:null,
    LoggedIn: false,
    token: null,
}


const getters = {
    getUsers(state) {
      var result = [...state.users];
      return result;
    },
    getUser(state) {
      var result = state.user?{...state.user}:null;
      return result;
    },
    isLoggedIn(state) {
      var result = state.LoggedIn && state.user && state.token;
      return result;
    },
    getToken(state) {
      var result = state.token;
      return result;
    },
}

const actions = {
    addUser(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.postRequest("/user", payload).then(response => {
            context.commit('addUser',response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    updateUser(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.patchRequest("/user/"+payload.id, payload).then(response=>{
            context.commit('updateUser', response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    deleteUser(context, payload ){
      context.commit('setLoading',true);
      return softsimApi.deleteRequest( `/user/`+ payload.id).then(response=>{
          context.commit('deleteUser', response.data );
          if(context.state.user && context.state.user.id == response.data.id)
            context.dispatch('destroyToken');
          return response;
      }).finally(() => {
          context.commit('setLoading',false);
      });
    },
    retrieveUsers(context) {
        return softsimApi.getRequest('/user' ).then(response => {
            context.commit('retrieveUsers', response.data);
            context.commit('setLoading',false);
        });
    },
    retrieveUserInfo(context) {
      context.commit('setLoading',true);
      return softsimApi.getRequest('currentuser')
        .then(response => {
          context.commit('retrieveUserInfo', response.data);
          return response;
        }).finally(() => {
          context.commit('setLoading',false);
      });
    },
    retrieveToken(context,credentials) {
      context.commit('setLoading',true);
      return softsimApi.postRequest('/login', {
        username: credentials.username,
        password: credentials.password,
        }).then(response=>{
          context.commit('retrieveToken', response.data);
        return response;
      }).finally(() => {
        context.commit('setLoading',false);
      });
    },
    destroyToken(context) {
      context.commit('setLoading',true);
      if (context.getters.isLoggedIn) {
        return new Promise((resolve, reject) => {
          softsimApi.postRequest('/logout')
            .then(response => {
              context.commit('destroyToken');
              context.commit('destroyUser');
              resolve(response)
            })
            .catch(error => {
              context.commit('destroyToken');
              context.commit('destroyUser');
              reject(error)
            }).finally(() => {
              context.commit('setLoading',false);
            });
        })
      }
    },

} 


const mutations = {
    setLoading(state,payload){
        state.loading=payload;
    },
    addUser(state,payload){
        state.users.unshift(payload);
    },
    updateUser(state,payload){
        const edited_item_index=state.users.findIndex(
            (item) => item.id === payload.id
            );
        if (edited_item_index !== -1)
            state.users[edited_item_index] = payload;
        if(state.user && state.user.id == payload.id)
          state.user = payload;
    },
    deleteUser(state,payload){
        state.users = state.users.filter((user)=>{
            return user.id!=payload.id;
        }); 
    },
    retrieveUsers(state,payload){
        state.users=payload.data;
    },
    retrieveUserInfo(state, payload) {
      state.user = payload;
      state.LoggedIn=true;
    },
    retrieveToken(state, payload) {
      state.token = payload.access_token;
    },
    destroyToken(state) {
      state.token = null;
      state.LoggedIn=false;
    },
    destroyUser(state) {
      state.user = null;
      state.LoggedIn=false;
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
