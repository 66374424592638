import softsimApi from "@/plugins/softsimApi";

const state = {
    loading: true,
    company_socials:[],
    current_company_socials:[],
    company_id: process.env.VUE_APP_COMPANYID,
}


const getters = {
    getCompanySocials(state) {
        var result = [...state.company_socials];
        return result;
    },
    getCurrentCompanySocials(state) {
        var result = [...state.current_company_socials];
        return result;
    }
}

const actions = {
    addCompanySocial(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.postRequest("/company_social", payload).then(response => {
            context.commit('addCompanySocial',response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    updateCompanySocial(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.patchRequest("/company_social/"+payload.id, payload).then(response=>{
            context.commit('updateCompanySocial', response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    deleteCompanySocial(context, payload ){
        context.commit('setLoading',true);
        return softsimApi.deleteRequest( `/company_social/`+ payload.id).then(response=>{
            context.commit('deleteCompanySocial', response.data );
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    retrieveCompanySocials(context) {
        return softsimApi.getRequest('/company_social' ).then(response => {
            context.commit('retrieveCompanySocials', response.data);
            context.commit('setLoading',false);
        });
    },

} 


const mutations = {
    setLoading(state,payload){
        state.loading=payload;
    },
    addCompanySocial(state,payload){
        state.company_socials.unshift(payload);
        state.current_company_socials = state.company_socials.filter((company_social) => company_social.company == state.company_id);
    },
    updateCompanySocial(state,payload){
        const edited_item_index=state.company_socials.findIndex(
            (item) => item.id === payload.id
            );
        if (edited_item_index !== -1)
            state.company_socials[edited_item_index] = payload;
        state.current_company_socials = state.company_socials.filter((company_social) => company_social.company == state.company_id);
    },
    deleteCompanySocial(state,payload){
        state.company_socials = state.company_socials.filter((company_social)=>{
            return company_social.id!=payload.id;
        });
        state.current_company_socials = state.company_socials.filter((company_social) => company_social.company == state.company_id);
    },
    retrieveCompanySocials(state,payload){
        state.company_socials=payload.data;
        state.current_company_socials = state.company_socials.filter((company_social) => company_social.company == state.company_id);
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
