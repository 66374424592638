import softsimApi from "@/plugins/softsimApi";

const state = {
    loading: false,
    contacts:[],
}


const getters = {
    getContacts(state) {
        var result = [...state.contacts];
        return result;
    }
}

const actions = { 

    addContact(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.postRequest("/contact", payload).then(response => {
            context.commit('addContact',response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    updateContact(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.patchRequest("/contact/"+payload.id, payload).then(response=>{
            context.commit('updateContact', response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    deleteContact(context, payload ){
        context.commit('setLoading',true);
        return softsimApi.deleteRequest( `/contact/`+ payload.id).then(response=>{
            context.commit('deleteContact', response.data );
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    retrieveContacts(context) {
        context.commit('setLoading',true);
        return softsimApi.getRequest('/contact' ).then(response => {
            context.commit('retrieveContacts', response.data);
            context.commit('setLoading',false);
        });
    },
    forwardEmail(context, payload) {
        console.log('Dispatching forwardEmail with:', payload);
        context.commit('setLoading', true); // Set loading state to true at the beginning of the action
        return softsimApi.postRequest("/forward-email", payload) // Use softsimApi to make a POST request
            .then(response => {
                console.log('Email forwarded successfully:', response);
                // Handle the successful email forwarding here
                // For example, you might want to commit a mutation to update the state
                // context.commit('emailForwardedSuccessfully', response.data);
                return response; // Return the response for further handling if needed
            })
            .catch(error => {
                // Handle any errors that occur during the request
                console.error('Error forwarding email:', error);
                // Optionally, commit a mutation to update the state with the error
                // context.commit('emailForwardingFailed', error);
                throw error; // Rethrow the error if you want calling code to handle it
            })
            .finally(() => {
                context.commit('setLoading', false); // Reset loading state to false once the action is complete
            });
    },
    

} 


const mutations = {
    setLoading(state,payload){
        state.loading=payload;
    },
    addContact(state,payload){
        state.contacts.unshift(payload);
    },
    updateContact(state,payload){
        const edited_item_index=state.contacts.findIndex(
            (item) => item.id === payload.id
            );
        if (edited_item_index !== -1)
            state.contacts[edited_item_index] = payload;
    },
    deleteContact(state,payload){
        state.contacts = state.contacts.filter((contact)=>{
            return contact.id!=payload.id;
        });
    },
    retrieveContacts(state,payload){
        state.contacts=payload.data;
    },

}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
