<template>
  <div class="idle-overlay">
    <v-card class="ma-auto" outlined>
        <v-card-title class="title text--secondary text-uppercase">
            <v-icon large>mdi-alert</v-icon> 
            <span class="ma-auto">{{$t("idlewarning")}}</span>
            <v-icon large>mdi-alert</v-icon> 
        </v-card-title>
        <v-card-text>{{$t("idledescription")}}</v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {},
  created() {},
};
</script>
<style scoped>
.idle-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
}
</style>