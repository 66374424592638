import softsimApi from "@/plugins/softsimApi";

const state = {
    loading: true,
    company_addresses:[],
    current_company_addresses:[],
    company_id: process.env.VUE_APP_COMPANYID,
}


const getters = {
    getCompanyAddresses(state) {
        var result = [...state.company_addresses];
        return result;
    },
    getCurrentCompanyAddresses(state) {
        var result = [...state.current_company_addresses];
        return result;
    }
}

const actions = {
    addCompanyAddress(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.postRequest("/company_address", payload).then(response => {
            context.commit('addCompanyAddress',response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    updateCompanyAddress(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.patchRequest("/company_address/"+payload.id, payload).then(response=>{
            context.commit('updateCompanyAddress', response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    deleteCompanyAddress(context, payload ){
        context.commit('setLoading',true);
        return softsimApi.deleteRequest( `/company_address/`+ payload.id).then(response=>{
            context.commit('deleteCompanyAddress', response.data );
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    retrieveCompanyAddresses(context) {
        return softsimApi.getRequest('/company_address' ).then(response => {
            context.commit('retrieveCompanyAddresses', response.data);
            context.commit('setLoading',false);
        });
    },

} 


const mutations = {
    setLoading(state,payload){
        state.loading=payload;
    },
    addCompanyAddress(state,payload){
        state.company_addresses.unshift(payload);
        state.current_company_addresses = state.company_addresses.filter((company_address) => company_address.company == state.company_id);
    },
    updateCompanyAddress(state,payload){
        const edited_item_index=state.company_addresses.findIndex(
            (item) => item.id === payload.id
            );
        if (edited_item_index !== -1)
            state.company_addresses[edited_item_index] = payload;
        state.current_company_addresses = state.company_addresses.filter((company_address) => company_address.company == state.company_id);
    },
    deleteCompanyAddress(state,payload){
        state.company_addresses = state.company_addresses.filter((company_address)=>{
            return company_address.id!=payload.id;
        });
        state.current_company_addresses = state.company_addresses.filter((company_address) => company_address.company == state.company_id);
    },
    retrieveCompanyAddresses(state,payload){
        state.company_addresses=payload.data;
        state.current_company_addresses = state.company_addresses.filter((company_address) => company_address.company == state.company_id);
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
