import softsimApi from "@/plugins/softsimApi";

const state = {
    loading: true,
    roles:[],
}


const getters = {
    getRoles(state) {
        var result = [...state.roles];
        return result;
    },
}

const actions = {
    addRole(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.postRequest("/role", payload).then(response => {
            context.commit('addRole',response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    updateRole(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.patchRequest("/role/"+payload.id, payload).then(response=>{
            context.commit('updateRole', response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    deleteRole(context, payload ){
        context.commit('setLoading',true);
        return softsimApi.deleteRequest( `/role/`+ payload.id).then(response=>{
            context.commit('deleteRole', response.data );
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    retrieveRoles(context) {
        return softsimApi.getRequest('/role' ).then(response => {
            context.commit('retrieveRoles', response.data);
            context.commit('setLoading',false);
        });
    },
    assignRoleUser(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.postRequest('/assign_role_user/'+payload.role+'/'+payload.user.id).then(response => {
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    unassignRoleUser(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.postRequest('/unassign_role_user/'+payload.user.id).then(response => {
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    updateRolePermissions(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.postRequest("/update_role_permissions/"+payload.role.id, payload).then(response=>{
            context.commit('updateRole', response.data );
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },

} 


const mutations = {
    setLoading(state,payload){
        state.loading=payload;
    },
    addRole(state,payload){
        state.roles.unshift(payload);
    },
    updateRole(state,payload){
        const edited_item_index=state.roles.findIndex(
            (item) => item.id === payload.id
            );
        if (edited_item_index !== -1)
            state.roles[edited_item_index] = payload;
    },
    deleteRole(state,payload){
        state.roles = state.roles.filter((role)=>{
            return role.id!=payload.id;
        });
    },
    retrieveRoles(state,payload){
        state.roles=payload.data;
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
