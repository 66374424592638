import softsimApi from "@/plugins/softsimApi";

const state = {
    loading: true,
    company_values:[],
    current_company_values:[],
    what_we_do:[],
    our_values:[],
    company_id: process.env.VUE_APP_COMPANYID,
}


const getters = {
    getCompanyValues(state) {
        var result = [...state.company_values];
        return result;
    },
    getCurrentCompanyValues(state) {
        var result = [...state.current_company_values];
        return result;
    },
    getWhatWeDo(state) {
        var result = [...state.what_we_do];
        return result;
    },
    getOurValues(state) {
        var result = [...state.our_values];
        return result;
    }
}

const actions = {
    addCompanyValue(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.postRequest("/company_value", payload).then(response => {
            context.commit('addCompanyValue',response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    updateCompanyValue(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.patchRequest("/company_value/"+payload.id, payload).then(response=>{
            context.commit('updateCompanyValue', response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    deleteCompanyValue(context, payload ){
        context.commit('setLoading',true);
        return softsimApi.deleteRequest( `/company_value/`+ payload.id).then(response=>{
            context.commit('deleteCompanyValue', response.data );
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    retrieveCompanyValues(context) {
        return softsimApi.getRequest('/company_value' ).then(response => {
            context.commit('retrieveCompanyValues', response.data);
            context.commit('setLoading',false);
        });
    },

} 


const mutations = {
    setLoading(state,payload){
        state.loading=payload;
    },
    addCompanyValue(state,payload){
        state.company_values.unshift(payload);
        state.current_company_values = state.company_values.filter((company_value) => company_value.company == state.company_id);
        state.what_we_do = state.current_company_values.filter(value=>{return value.type=='whatwedo'});
        state.our_values = state.current_company_values.filter(value=>{return value.type=='ourvalues'});
    },
    updateCompanyValue(state,payload){
        const edited_item_index=state.company_values.findIndex(
            (item) => item.id === payload.id
            );
        if (edited_item_index !== -1)
            state.company_values[edited_item_index] = payload;
        state.current_company_values = state.company_values.filter((company_value) => company_value.company == state.company_id);
        state.what_we_do = state.current_company_values.filter(value=>{return value.type=='whatwedo'});
        state.our_values = state.current_company_values.filter(value=>{return value.type=='ourvalues'});
    },
    deleteCompanyValue(state,payload){
        state.company_values = state.company_values.filter((company_value)=>{
            return company_value.id!=payload.id;
        });
        state.current_company_values = state.company_values.filter((company_value) => company_value.company == state.company_id);
        state.what_we_do = state.current_company_values.filter(value=>{return value.type=='whatwedo'});
        state.our_values = state.current_company_values.filter(value=>{return value.type=='ourvalues'});
    },
    retrieveCompanyValues(state,payload){
        state.company_values=payload.data;
        state.current_company_values = state.company_values.filter((company_value) => company_value.company == state.company_id);
        state.what_we_do = state.current_company_values.filter(value=>{return value.type=='whatwedo'});
        state.our_values = state.current_company_values.filter(value=>{return value.type=='ourvalues'});
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
