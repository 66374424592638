import softsimApi from "@/plugins/softsimApi";

const state = {
    loading: true,
    companies:[],
    company_id: process.env.VUE_APP_COMPANYID,
    company:{},
}


const getters = {
    getCompanies(state) {
        var result = [...state.companies];
        return result;
    },
    getCompany(state) {
        var result = {...state.company};
        return result;
    }
}

const actions = {
    addCompany(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.postRequest("/company", payload).then(response => {
            context.commit('addCompany',response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    updateCompany(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.patchRequest("/company/"+payload.id, payload).then(response=>{
            context.commit('updateCompany', response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    deleteCompany(context, payload ){
        context.commit('setLoading',true);
        return softsimApi.deleteRequest( `/company/`+ payload.id).then(response=>{
            context.commit('deleteCompany', response.data );
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    retrieveCompanies(context) {
        return softsimApi.getRequest('/company' ).then(response => {
            context.commit('retrieveCompanies', response.data);
            context.commit('setLoading',false);
        });
    },

} 


const mutations = {
    setLoading(state,payload){
        state.loading=payload;
    },
    addCompany(state,payload){
        state.companies.unshift(payload);
        state.company = state.companies.find(company => company.id == state.company_id);
    },
    updateCompany(state,payload){
        const edited_item_index=state.companies.findIndex(
            (item) => item.id === payload.id
            );
        if (edited_item_index !== -1)
            state.companies[edited_item_index] = payload;
        state.company = state.companies.find(company => company.id == state.company_id);
    },
    deleteCompany(state,payload){
        state.companies = state.companies.filter((company)=>{
            return company.id!=payload.id;
        });
        state.company = state.companies.find(company => company.id == state.company_id);
    },
    retrieveCompanies(state,payload){
        state.companies=payload.data;
        state.company = state.companies.find(company => company.id == state.company_id);
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
