import softsimApi from "@/plugins/softsimApi";

const state = {
    loading: true,
    statistics:[],
    clients:{},
    partners:{},
}


const getters = {
    getStatistics(state) {
        var result = [...state.statistics];
        return result;
    },
    getClientsStatistics(state) {
        var result = {...state.clients};
        return result.value;
    },
    getPartnersStatistics(state) {
        var result = {...state.partners};
        return result.value;
    },
}

const actions = {
    addStatistic(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.postRequest("/statistic", payload).then(response => {
            context.commit('addStatistic',response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    updateStatistic(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.patchRequest("/statistic/"+payload.id, payload).then(response=>{
            context.commit('updateStatistic', response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    deleteStatistic(context, payload ){
        context.commit('setLoading',true);
        return softsimApi.deleteRequest( `/statistic/`+ payload.id).then(response=>{
            context.commit('deleteStatistic', response.data );
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    retrieveStatistics(context) {
        return softsimApi.getRequest('/statistic' ).then(response => {
            context.commit('retrieveStatistics', response.data);
            context.commit('setLoading',false);
        });
    },

} 


const mutations = {
    setLoading(state,payload){
        state.loading=payload;
    },
    addStatistic(state,payload){
        state.statistics.unshift(payload);
    },
    updateStatistic(state,payload){
        const edited_item_index=state.statistics.findIndex(
            (item) => item.id === payload.id
            );
        if (edited_item_index !== -1)
            state.statistics[edited_item_index] = payload;
    },
    deleteStatistic(state,payload){
        state.statistics = state.statistics.filter((statistic)=>{
            return statistic.id!=payload.id;
        });
    },
    retrieveStatistics(state,payload){
        state.statistics = payload.data;
        state.clients = state.statistics.filter((stat)=>{return stat.module=='clients'})[0];
        state.partners = state.statistics.filter((stat)=>{return stat.module=='partners'})[0];
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
