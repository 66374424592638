import softsimApi from "@/plugins/softsimApi";

const state = {
    loading: true,
    permissions:[],
}


const getters = {
    getPermissions(state) {
        var result = [...state.permissions];
        return result;
    },
}

const actions = {
    addPermission(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.postRequest("/permission", payload).then(response => {
            context.commit('addPermission',response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    updatePermission(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.patchRequest("/permission/"+payload.id, payload).then(response=>{
            context.commit('updatePermission', response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    deletePermission(context, payload ){
        context.commit('setLoading',true);
        return softsimApi.deleteRequest( `/permission/`+ payload.id).then(response=>{
            context.commit('deletePermission', response.data );
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    retrievePermissions(context) {
        return softsimApi.getRequest('/permission' ).then(response => {
            context.commit('retrievePermissions', response.data);
            context.commit('setLoading',false);
        });
    },

} 


const mutations = {
    setLoading(state,payload){
        state.loading=payload;
    },
    addPermission(state,payload){
        state.permissions.unshift(payload);
    },
    updatePermission(state,payload){
        const edited_item_index=state.permissions.findIndex(
            (item) => item.id === payload.id
            );
        if (edited_item_index !== -1)
            state.permissions[edited_item_index] = payload;
    },
    deletePermission(state,payload){
        state.permissions = state.permissions.filter((permission)=>{
            return permission.id!=payload.id;
        });
    },
    retrievePermissions(state,payload){
        state.permissions=payload.data;
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
