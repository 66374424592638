import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';

import contact from './modules/contact'
import client from './modules/client'
import partner from './modules/partner'
import blog from './modules/blog'
import product from './modules/product'
import product_service from './modules/product_service'
import product_attachment from './modules/product_attachment'
import statistic from './modules/statistic'
import service from './modules/service'
import gallery from './modules/gallery'
import opportunity from './modules/opportunity'
import user from './modules/user'
import company from './modules/company'
import company_address from './modules/company_address'
import company_social from './modules/company_social'
import company_value from './modules/company_value'
import file from './modules/file'
import role from './modules/role'
import permission from './modules/permission'

//import softsimApi from "@/plugins/softsimApi";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: false,
  },
  plugins: [createPersistedState()],
  getters: {
  },
  actions: {
  },
  mutations: {
    setLoading(state,payload){
        state.loading=payload;
    },
  },

  modules: {
    contact,
    client,
    partner,
    blog,
    product,
    product_service,
    product_attachment,
    statistic,
    service,
    gallery,
    opportunity,
    user,
    company,
    company_address,
    company_social,
    company_value,
    file,
    role,
    permission,
  }
})
