import softsimApi from "@/plugins/softsimApi";

const state = {
    loading: false,
    files:[],
}


const getters = {
    getFiles(state) {
        var result = state.files;
        return result;
    }
}

const actions = {
    addFile(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.postRequest("/file", payload, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }
        ).then(response=>{
            context.commit('addFile',response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    deleteFile(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.postRequest("/file/destroy", payload).then(response=>{
            context.commit('deleteFile',response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },

    async downloadFile(context, payload){
        context.commit('setLoading',true);
        return await softsimApi.getRequest('/file/download/'+payload.name,{responseType: 'blob'} ).then(response => {
            let filename = payload.name;
            let mime = 'application/octet-stream';
            // It is necessary to create a new blob object with mime-type explicitly set
            // otherwise only Chrome works like it should
            const blob = new Blob([response.data], {type: mime});
            if (typeof window.navigator.msSaveBlob !== 'undefined') {
              // IE doesn't allow using a blob object directly as link href.
              // Workaround for "HTML7007: One or more blob URLs were
              // revoked by closing the blob for which they were created.
              // These URLs will no longer resolve as the data backing
              // the URL has been freed."
              window.navigator.msSaveBlob(blob, filename);
              return;
            }
            // Other browsers
            // Create a link pointing to the ObjectURL containing the blob
            const blobURL = window.URL.createObjectURL(blob);
            const tempLink = document.createElement('a');
            tempLink.style.display = 'none';
            tempLink.href = blobURL;
            tempLink.setAttribute('download', filename);
            // Safari thinks _blank anchor are pop ups. We only want to set _blank
            // target if the browser does not support the HTML5 download attribute.
            // This allows you to download files in desktop safari if pop up blocking
            // is enabled.
            if (typeof tempLink.download === 'undefined') {
              tempLink.setAttribute('target', '_blank');
            }
            document.body.appendChild(tempLink);
            tempLink.click();
            document.body.removeChild(tempLink);
            setTimeout(() => {
              // For Firefox it is necessary to delay revoking the ObjectURL
              window.URL.revokeObjectURL(blobURL);
            }, 100);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
        
    },
} 


const mutations = {
    setLoading(state,payload){
        state.loading=payload;
    },
    addFile(state,payload){
        state.files.unshift(payload);
    },
    deleteFile(state,payload){
        state.files = state.files.filter((file)=>{
            return file.id!=payload.id;
        });
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
