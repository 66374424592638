import softsimApi from "@/plugins/softsimApi";

const state = {
    loading: true,
    gallery:[],
}


const getters = {
    getGallery(state) {
        var result = [...state.gallery];
        return result;
    }
}

const actions = {
    addGallery(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.postRequest("/gallery", payload).then(response => {
            context.commit('addGallery',response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    updateImage(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.patchRequest("/gallery/"+payload.id, payload).then(response=>{
            context.commit('updateImage', response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    deleteGallery(context, payload ){
        context.commit('setLoading',true);
        return softsimApi.deleteRequest( `/gallery/`+ payload.id).then(response=>{
            context.commit('deleteGallery', response.data );
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    retrieveGallery(context) {
        return softsimApi.getRequest('/gallery' ).then(response => {
            context.commit('retrieveGallery', response.data);
            context.commit('setLoading',false);
        });
    },

} 


const mutations = {
    setLoading(state,payload){
        state.loading=payload;
    },
    addGallery(state,payload){
        state.gallery.unshift(payload);
    },
    updateImage(state,payload){
        const edited_item_index=state.gallery.findIndex(
            (item) => item.id === payload.id
            );
        if (edited_item_index !== -1)
            state.gallery[edited_item_index] = payload;
    },
    deleteGallery(state,payload){
        state.gallery = state.gallery.filter((image)=>{
            return image.id!=payload.id;
        });
    },
    retrieveGallery(state,payload){
        state.gallery=payload.data;
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
