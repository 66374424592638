<template>
  <v-app class="pa-0 ma-0">
    <idle-overlay v-if="isIdle"></idle-overlay>
    <vue-particles
      :particleOpacity="0.6"
      :particlesNumber="30"
      shapeType="circle"
      :particleSize="3"
      :linesWidth="1"
      :lineLinked="true"
      :lineOpacity="0.3"
      :linesDistance="160"
      :moveSpeed="3"
      :hoverEffect="true"
      hoverMode="grab"
      :clickEffect="true"
      clickMode="push"
      color="#0d3b3f"
      linesColor="#0d3b3f"
      class="particles"
    >
    </vue-particles>
    <router-view></router-view>
    <notifications group="app" position="bottom right">
    </notifications>
  </v-app>
</template>

<script>
import idleoverlay from "@/components/Layout/IdleOverlay.vue";
export default {
  name: "App",

  data() {
    return {};
  },
  components: {
    "idle-overlay": idleoverlay,
  },
  created() {
    this.setTheme();
    this.setLanguage();
  },
  computed: {
    isIdle() {
      return this.$store.state.idleVue.isIdle;
    },
  },
  watch: {},
  methods: {
    setTheme: function () {
      localStorage.setItem("theme", localStorage.getItem("theme") ?? "light");
      localStorage.getItem("theme") == "dark"
        ? (this.$vuetify.theme.dark = true)
        : (this.$vuetify.theme.dark = false);
    },
    setLanguage: function () {
      localStorage.setItem(
        "language",
        localStorage.getItem("language") ?? "en"
      );
      localStorage.getItem("language") == "en"
        ? (this.$i18n.locale = "en")
        : (this.$i18n.locale = "fr");
    },
  },
};
</script>

<style>
@import "./assets/styles/app.css";
</style>