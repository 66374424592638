import softsimApi from "@/plugins/softsimApi";

const state = {
    loading: true,
    product_services:[],
}


const getters = {
    getProductServices(state) {
        var result = [...state.product_services];
        return result;
    }
}

const actions = {
    addProductService(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.postRequest("/product_service", payload).then(response => {
            context.commit('addProductService',response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    updateProductService(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.patchRequest("/product_service/"+payload.id, payload).then(response=>{
            context.commit('updateProductService', response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    deleteProductService(context, payload ){
        context.commit('setLoading',true);
        return softsimApi.deleteRequest( `/product_service/`+ payload.id).then(response=>{
            context.commit('deleteProductService', response.data );
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    retrieveProductServices(context) {
        return softsimApi.getRequest('/product_service' ).then(response => {
            context.commit('retrieveProductServices', response.data);
            context.commit('setLoading',false);
        });
    },

} 


const mutations = {
    setLoading(state,payload){
        state.loading=payload;
    },
    addProductService(state,payload){
        state.product_services.unshift(payload);
    },
    updateProductService(state,payload){
        const edited_item_index=state.product_services.findIndex(
            (item) => item.id === payload.id
            );
        if (edited_item_index !== -1)
            state.product_services[edited_item_index] = payload;
    },
    deleteProductService(state,payload){
        state.product_services = state.product_services.filter((product_service)=>{
            return product_service.id!=payload.id;
        });
    },
    retrieveProductServices(state,payload){
        state.product_services=payload.data;
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
